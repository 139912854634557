import * as React from "react"
import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import Footer from "../components/footer";
import Header from "../components/header";

const ContactPage = ({data}) => {

    const partners = data.allContentfulPartners.nodes;
    const contact = data.contentfulContact;
    const teams = data.allContentfulTeam.nodes
    const pages = data.allContentfulPages.nodes

    return (
        <Layout>
            <Helmet>
                <title>Kapcsolat - Zempléni Hiúzok</title>
            </Helmet>
            <Header pages={pages} teams={teams}/>
            <main>
                <section className="section section-variant-1 bg-gray-100">
                    <div className="container">
                        <div className="row row-50">
                            <div className="col-lg-6">
                                <article className="heading-component">
                                    <div className="heading-component-inner">
                                        <h5 className="heading-component-title">Jégcsarnok elérhetőségei</h5>
                                    </div>
                                </article>

                                <div className="contact-list">
                                    <dl>
                                        <dt>Nyitvatartás</dt>
                                        <dd>
                                            <span className="icon icon-primary mdi mdi-alarm-check"/>
                                            {renderRichText(contact.iceRinkOpeningHours)}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>E-mail</dt>
                                        <dd><span className="icon icon-primary mdi mdi-email-outline"/><a
                                            className="link" href={`mailto:${contact.iceRinkEmail}`}>{contact.iceRinkEmail}</a></dd>
                                    </dl>
                                    <dl>
                                        <dt>Cím</dt>
                                        <dd><span className="icon icon-primary mdi mdi-map-marker"/><a
                                            className="link" href="https://goo.gl/maps/ebMhnhgxEUVnMN9m9">{contact.iceRinkAddress}</a></dd>
                                    </dl>
                                    <dl>
                                        <dt>Telefonszám</dt>
                                        <dd><span className="icon icon-primary mdi mdi-phone"/><a
                                            className="link link-md" href={`tel:${contact.iceRinkPhone}`}>{contact.iceRinkPhone}</a></dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <article className="heading-component">
                                    <div className="heading-component-inner">
                                        <h5 className="heading-component-title">Egyesületünk elérhetőségei</h5>
                                    </div>
                                </article>

                                <div className="contact-list">
                                    <dl>
                                        <dt>E-mail</dt>
                                        <dd><span className="icon icon-primary mdi mdi-email-outline"/><a
                                            className="link" href={`mailto:${contact.gmEmail}`}>{contact.gmEmail}</a></dd>
                                    </dl>
                                    <dl>
                                        <dt>Cím</dt>
                                        <dd><span className="icon icon-primary mdi mdi-map-marker"/><a
                                            className="link" href="https://goo.gl/maps/ebMhnhgxEUVnMN9m9">{contact.gmAddress}</a></dd>
                                    </dl>
                                    <dl>
                                        <dt>Telefonszám</dt>
                                        <dd><span className="icon icon-primary mdi mdi-phone"/><a
                                            className="link link-md" href={`tel:${contact.gmPhoneNumber}`}>{contact.gmPhoneNumber}</a></dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className={'row mt-5'}>
                            <div className={'col-12'}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2648.2707584462096!2d21.640605!3d48.412946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4738cdc860a004ff%3A0xf436f182f34232f5!2zU8OhdG9yYWxqYcO6amhlbHksIFRvcnpzw6FzIHUuIDI1LCAzOTgw!5e0!3m2!1shu!2shu!4v1670780641012!5m2!1shu!2shu"
                                    width="100%" height="650" allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"/>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer partners={partners}/>
        </Layout>
    )
}

export default ContactPage

export const query = graphql`
  query getContact {
  contentfulContact {
    id
    gmAddress
    gmEmail
    gmPhoneNumber
    iceRinkAddress
    iceRinkEmail
    iceRinkOpeningHours {
      raw
    }
    iceRinkPhone
  }
  allContentfulPartners {
    nodes {
      title
      logo {
        url
      }
    }
  }
  allContentfulPages {
    nodes {
      text {
        raw
      }
      title
      slug
    }
  }
  allContentfulTeam(sort: {fields: title, order: ASC}) {
    nodes {
      title
      label
    }
  }
}
`
